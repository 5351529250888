// vendors
import styled, { keyframes } from 'styled-components';

const scale = keyframes`
  from {
    transform: scale(0.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Dot = styled.span`
  display: block;

  width: 0.7em;
  height: 0.7em;

  margin: 0 0.15em;

  background-color: currentColor;
  border-radius: 50%;

  transform: scale(0.25);
  opacity: 0;

  animation: ${scale} 750ms alternate infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 250ms;
  }

  &:nth-child(3) {
    animation-delay: 500ms;
  }
`;

export const Container = styled.div`
  display: flex;
`;
