/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components';
import { rem, em } from 'polished';
import theme from '../../styles/theme';
import { speed } from '../../styles/animation';
import { lessThan, greaterThan } from '../../utils/mediaQuery';
import breakpoints from '../../styles/breakpoints';
import { fontSizes } from '../../styles/typography';
import responsiveStyle from '../../utils/responsiveStyle';

export const IconWrapper = styled.span`
  width: ${em(32, 19)};
  min-width: 9px;
`;

export const TextWrapper = styled.span`
  flex-grow: 1;
`;

export const biggerStyle = css`
  && {
    min-width: ${(props) => (props.hasIconOnly ? '0' : rem(148))};
    max-width: ${rem(360)};
    /* min-height: ${theme.spaces.sm6}rem; */
    /* padding: ${theme.spaces.s0}rem ${theme.spaces.sm2}rem; */
    padding: ${em(16, theme.button.fontSizes[0])} ${em(
  20,
  theme.button.fontSizes[0]
)};

    font-weight: ${theme.fontWeights.body};
    font-size: ${em(theme.button.fontSizes[0], fontSizes.body[0])};
    line-height: ${theme.button.lineHeights[1]};

    ${lessThan(breakpoints.fonts[0])} {
      padding: ${em(12, theme.button.fontSizes[0])} ${em(
  16,
  theme.button.fontSizes[0]
)};
    }

    ${greaterThan(breakpoints.fonts[0])} {
      font-size: ${em(theme.button.fontSizes[1], fontSizes.body[1])};
    }

    ${greaterThan(breakpoints.fonts[1])} {
      font-size: ${em(theme.button.fontSizes[2], fontSizes.body[2])};
    }

    ${IconWrapper} {
      width: 0.5em;
    }
  }
`;

export const outlineStyle = css`
  color: ${(props) =>
    props.primary ? theme.colors.primary : theme.colors.secondary};

  background-color: transparent;

  &:disabled {
    color: ${theme.colors.disabled};

    background-color: transparent;
    border-color: currentColor;
  }

  &:focus,
  &:hover:not(:disabled) {
    color: ${theme.colors.white};

    background-color: ${(props) =>
      props.primary ? theme.colors.primary : theme.colors.secondary};
  }
`;

export const whiteStyle = css`
  color: ${(props) =>
    props.outlined ? theme.colors.white : theme.colors.black};

  background-color: ${(props) =>
    props.outlined ? 'transparent' : theme.colors.white};
  border-color: ${theme.colors.white};

  &:focus,
  &:hover:not(:disabled) {
    color: ${theme.colors.black};

    background-color: ${(props) =>
      props.outlined ? theme.colors.white : theme.colors.veryLightPink};
    border-color: ${(props) =>
      props.outlined ? theme.colors.white : theme.colors.veryLightPink};
  }
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: ${(props) => (props.hasIconOnly ? '0' : rem(227))};
  max-width: ${rem(326)};
  min-height: ${theme.spaces.sm5}rem;
  padding: ${theme.spaces.s0}rem ${theme.spaces.sm2}rem;

  color: ${theme.colors.white};
  font-weight: 500;
  line-height: ${theme.button.lineHeights[0]};
  text-align: center;
  text-decoration: none;

  background-color: ${(props) =>
    props.primary ? theme.colors.primary : theme.colors.secondary};
  border: ${theme.button.borderThin}px solid
    ${(props) =>
      props.primary ? theme.colors.primary : theme.colors.secondary};

  outline: 0;
  cursor: pointer;

  transition-timing-function: ease-in-out;
  transition-duration: ${speed.superfast};
  transition-property: color, background-color, box-shadow;

  ${responsiveStyle('fontSize', [15, 17, 19], breakpoints.fonts)}

  ${(props) =>
    props.hasIcon &&
    css`
      ${TextWrapper} {
        text-align: ${props.iconFirst ? 'right' : 'left'};
        margin: ${props.iconFirst
          ? `0 0 0 ${theme.spaces.s0}rem`
          : `0 ${theme.spaces.s0}rem 0 0`};
        order: ${props.iconFirst ? '1' : '-1'};
      }
    `}

  &:disabled {
    color: ${theme.colors.brownGrey600};

    background-color: ${theme.colors.disabled};
    border-color: ${theme.colors.disabled};

    cursor: not-allowed;
  }

  &:focus,
  &:hover:not(:disabled) {
    color: ${theme.colors.white};

    background-color: ${(props) =>
      props.primary ? theme.colors.azure600 : theme.colors.mineShaft400};
    border-color: ${(props) =>
      props.primary ? theme.colors.azure600 : theme.colors.mineShaft400};
  }

  appearance: none;

  ${(props) => props.outlined && outlineStyle}

  ${(props) => props.white && whiteStyle}
`;

export default StyledButton;
