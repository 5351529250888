import { rem } from 'polished';
import { greaterThanCondition } from './mediaQuery';
import { findValueWithTwoPoint } from './math/linearEquation';

const getClosestValueIndexBefore = (array, startIndex) => {
  const index = array
    .slice(0, startIndex)
    .reverse()
    .findIndex((e) => e !== undefined && e !== null);

  return startIndex - 1 - index;
};

const getClosestValueIndexAfter = (array, startIndex) => {
  const index = array
    .slice(startIndex)
    .findIndex((e) => e !== undefined && e !== null);

  return startIndex + index;
};

export default (property, values = [], breakpoints = []) => {
  if (values.length > breakpoints.length + 1)
    throw new Error(`They can't be more values then breakpoints`);

  const result = {};
  let filteredValues = [...values];

  if (values.length === 2) {
    filteredValues = breakpoints.map((_, index) => {
      if (index === 0) return values[0];

      return undefined;
    });

    filteredValues.push(values[1]);
  }

  filteredValues.forEach((size, index) => {
    let value = size;

    if (size === null) return;

    if (size === undefined) {
      const y1index = getClosestValueIndexBefore(filteredValues, index);
      const x1index = y1index - 1;
      const x1value = x1index < 0 ? '320' : breakpoints[x1index];

      const y2index = getClosestValueIndexAfter(filteredValues, index);
      const x2index = y2index - 1;
      const x2value = breakpoints[x2index];

      value = findValueWithTwoPoint(
        [
          { x: x1value, y: filteredValues[y1index] },
          { x: x2value, y: filteredValues[y2index] },
        ],
        breakpoints[index - 1]
      );
    }

    if (index === 0) {
      result[property] = rem(value);
    }

    if (index > 0) {
      result[`@media ${greaterThanCondition(breakpoints[index - 1])}`] = {
        [property]: rem(value),
      };
    }
  });

  return result;
};
