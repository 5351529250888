import {
  fontFamilies,
  fontColors,
  fontSizes,
  lineHeights,
  fontWeights,
} from './typography';
import zIndices from './zIndices';
import breakpoints from './breakpoints';
import spaces from './spaces';
import colors from './colors';
import layouts from './layouts';

export default {
  colors: {
    primary: colors.azure,
    secondary: colors.mineShaft,
    disabled: colors.brownGrey100,
    error: colors.sunsetOrange,
    success: colors.emerald,
    ...colors,
  },
  fonts: {
    ...fontFamilies,
  },
  fontColors,
  fontSizes,
  fontWeights,
  lineHeights,
  breakpoints,
  zIndices,
  spaces,
  layouts,
  button: {
    fontSizes: [15, 19, 30],
    lineHeights: [1.53, 0.93],
    borderThin: 2,
  },
  textInput: {
    fontSizes: [13, 19],
  },
  checkedInput: {
    size: [18, 30],
    fontSizes: [20, 28],
    lineHeights: [1.25, 1.33],
    marginRight: [13, 22],
    color: colors.brownGrey,
  },
};
