// vendors
import styled from 'styled-components';
import colors from '../../../styles/colors';
import Button from '../../Button';

export const Container = styled.div`
  background-color: ${colors.veryLightPink};

  :first-child {
    padding-top: 110px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1280px;
  margin: auto;
  padding: 33px 40px;
`;

export const IconWrapper = styled.div`
  flex-basis: 32px;
  flex-shrink: 0;
  width: 32px;
  margin-right: 15px;
`;

export const MessageWrapper = styled.div`
  flex-grow: 1;
`;

export const DismissButton = styled(Button)`
  &&& {
    min-width: initial;

    word-break: keep-all;
  }
`;

export const DismissButtonWrapper = styled.div`
  flex-shrink: 0;
  margin-left: 30px;
`;
