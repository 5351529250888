/**
 * Azure
 */
const azure = '#00aeef';
const azure100 = '#66cef5';
const azure200 = '#4cc6f3';
const azure300 = '#33bef2';
const azure400 = '#19b6f0';
const azure500 = '#00aeef';
const azure600 = '#009cd7';
const azure700 = '#008bbf';
const azure800 = '#0079a7';
const azure900 = '#00688f';

/**
 * Robin Egg Blue
 */
const robinEggBlue = '#89defe';
const robinEggBlue100 = '#b8ebfe';
const robinEggBlue200 = '#ace7fe';
const robinEggBlue300 = '#a0e4fe';
const robinEggBlue400 = '#94e1fe';
const robinEggBlue500 = '#89defe';
const robinEggBlue600 = '#7bc7e4';
const robinEggBlue700 = '#6db1cb';
const robinEggBlue800 = '#5f9bb1';
const robinEggBlue900 = '#528598';

/**
 * Ice Blue
 */
const iceBlue = '#def5fd';
const iceBlue100 = '#ebf9fd';
const iceBlue200 = '#e7f8fd';
const iceBlue300 = '#e4f7fd';
const iceBlue400 = '#e1f6fd';
const iceBlue500 = '#def5fd';
const iceBlue600 = '#c7dce3';
const iceBlue700 = '#b1c4ca';
const iceBlue800 = '#9babb1';
const iceBlue900 = '#859397';

/**
 * Very Light Pink
 */
const veryLightPink = '#e8e8e8';
const veryLightPink100 = '#f1f1f1';
const veryLightPink200 = '#eee';
const veryLightPink300 = '#ececec';
const veryLightPink400 = '#eaeaea';
const veryLightPink500 = '#e8e8e8';
const veryLightPink600 = '#d0d0d0';
const veryLightPink700 = '#b9b9b9';
const veryLightPink800 = '#a2a2a2';
const veryLightPink900 = '#8b8b8b';

/**
 * Water Blue
 */
const waterBlue = '#1186c1';
const waterBlue100 = '#70b6d9';
const waterBlue200 = '#58aad3';
const waterBlue300 = '#409ecd';
const waterBlue400 = '#2892c7';
const waterBlue500 = '#1186c1';
const waterBlue600 = '#0f78ad';
const waterBlue700 = '#0d6b9a';
const waterBlue800 = '#0b5d87';
const waterBlue900 = '#0a5073';

/**
 * Twilight Blue
 */
const twilightBlue = '#095289';
const twilightBlue100 = '#6b97b8';
const twilightBlue200 = '#5285ac';
const twilightBlue300 = '#3a74a0';
const twilightBlue400 = '#216394';
const twilightBlue500 = '#095289';
const twilightBlue600 = '#08497b';
const twilightBlue700 = '#07416d';
const twilightBlue800 = '#06395f';
const twilightBlue900 = '#053152';

/**
 * Wild Sand
 */
const wildSand = '#f6f6f6';
const wildSand100 = '#f9f9f9';
const wildSand200 = '#f8f8f8';
const wildSand300 = '#f7f7f7';
const wildSand400 = '#f6f6f6';
const wildSand500 = '#f6f6f6';
const wildSand600 = '#ddd';
const wildSand700 = '#c4c4c4';
const wildSand800 = '#acacac';
const wildSand900 = '#939393';

/**
 * Mine Shaft
 */
const mineShaft = '#292929';
const mineShaft100 = '#7e7e7e';
const mineShaft200 = '#696969';
const mineShaft300 = '#535353';
const mineShaft400 = '#3e3e3e';
const mineShaft500 = '#292929';
const mineShaft600 = '#242424';
const mineShaft700 = '#202020';
const mineShaft800 = '#1c1c1c';
const mineShaft900 = '#181818';

/**
 * Brown Grey
 */
const brownGrey = '#acacac';
const brownGrey100 = '#cdcdcd';
const brownGrey200 = '#c4c4c4';
const brownGrey300 = '#bcbcbc';
const brownGrey400 = '#b4b4b4';
const brownGrey500 = '#acacac';
const brownGrey600 = '#9a9a9a';
const brownGrey700 = '#898989';
const brownGrey800 = '#787878';
const brownGrey900 = '#676767';

const white = '#ffffff';

const black = mineShaft;

const sunsetOrange = '#fc4b4b';

const emerald = '#3ad23d';

const red = sunsetOrange;

export default {
  robinEggBlue,
  robinEggBlue100,
  robinEggBlue200,
  robinEggBlue300,
  robinEggBlue400,
  robinEggBlue500,
  robinEggBlue600,
  robinEggBlue700,
  robinEggBlue800,
  robinEggBlue900,

  iceBlue,
  iceBlue100,
  iceBlue200,
  iceBlue300,
  iceBlue400,
  iceBlue500,
  iceBlue600,
  iceBlue700,
  iceBlue800,
  iceBlue900,

  veryLightPink,
  veryLightPink100,
  veryLightPink200,
  veryLightPink300,
  veryLightPink400,
  veryLightPink500,
  veryLightPink600,
  veryLightPink700,
  veryLightPink800,
  veryLightPink900,

  waterBlue,
  waterBlue100,
  waterBlue200,
  waterBlue300,
  waterBlue400,
  waterBlue500,
  waterBlue600,
  waterBlue700,
  waterBlue800,
  waterBlue900,

  twilightBlue,
  twilightBlue100,
  twilightBlue200,
  twilightBlue300,
  twilightBlue400,
  twilightBlue500,
  twilightBlue600,
  twilightBlue700,
  twilightBlue800,
  twilightBlue900,

  wildSand,
  wildSand100,
  wildSand200,
  wildSand300,
  wildSand400,
  wildSand500,
  wildSand600,
  wildSand700,
  wildSand800,
  wildSand900,

  white,
  black,
  red,
  sunsetOrange,
  emerald,

  azure,
  azure100,
  azure200,
  azure300,
  azure400,
  azure500,
  azure600,
  azure700,
  azure800,
  azure900,

  mineShaft,
  mineShaft100,
  mineShaft200,
  mineShaft300,
  mineShaft400,
  mineShaft500,
  mineShaft600,
  mineShaft700,
  mineShaft800,
  mineShaft900,

  brownGrey,
  brownGrey100,
  brownGrey200,
  brownGrey300,
  brownGrey400,
  brownGrey500,
  brownGrey600,
  brownGrey700,
  brownGrey800,
  brownGrey900,
};
