// vendors
import React from 'react';
import theme from '../styles/theme';
import colors from '../styles/colors';

const IconError = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 110.89 110.89'
    {...props}
  >
    <circle fill={theme.colors.error} cx='55.46' cy='55.47' r='55.38' />
    <polygon
      className={colors.veryLightPink300}
      points='78.96 38.96 71.97 31.97 55.46 48.48 38.95 31.97 31.96 38.96 48.47 55.47 31.96 71.98 38.95 78.97 55.46 62.46 71.97 78.97 78.96 71.98 62.45 55.47 78.96 38.96'
    />
  </svg>
);

export default IconError;
