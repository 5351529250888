// vendors
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import randomString from '../utils/randomString';
import NotificationsCenter from '../components/NotificationsCenter/NotificationsCenter';

export const NotificationsCenterContext = React.createContext();

export const useNotificationsCenter = () =>
  useContext(NotificationsCenterContext);

const defaultNotificationOptions = {
  autoDismiss: false,
  autoDismissTimeout: 6000,
  onDismiss: () => {},
  dismissButtonLabel: 'Fermer',
};

export const NotificationsCenterProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (node, options = {}) => {
    const id = options.id || randomString();

    setNotifications((state) => {
      const newNotification = {
        content: node,
        id,
        ...defaultNotificationOptions,
        ...options,
      };

      return [...state, newNotification];
    });

    return id;
  };

  const removeNotification = (id) => {
    setNotifications((state) => {
      const newState = state.filter((n) => n.id !== id);

      return [...newState];
    });
  };

  const updateNotification = (id, options = {}) => {
    setNotifications((state) => {
      const i = state.findIndex((notification) => notification.id === id);

      const updatedNotification = { ...state[i], ...options };

      return [...state.slice(0, i), updatedNotification, ...state.slice(i + 1)];
    });
  };

  const removeAllNotifications = () => {
    setNotifications([]);
  };

  const notificationStack = () => {
    return notifications;
  };

  return (
    <NotificationsCenterContext.Provider
      value={{
        addNotification,
        removeNotification,
        updateNotification,
        removeAllNotifications,
        notificationStack,
      }}
    >
      {children}

      <NotificationsCenter
        removeNotification={removeNotification}
        notifications={notifications}
      />
    </NotificationsCenterContext.Provider>
  );
};

NotificationsCenterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
