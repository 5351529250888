// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-conseils-postoperatoires-jsx": () => import("./../../../src/pages/conseils-postoperatoires.jsx" /* webpackChunkName: "component---src-pages-conseils-postoperatoires-jsx" */),
  "component---src-pages-conseils-preoperatoires-jsx": () => import("./../../../src/pages/conseils-preoperatoires.jsx" /* webpackChunkName: "component---src-pages-conseils-preoperatoires-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-equipe-jsx": () => import("./../../../src/pages/equipe.jsx" /* webpackChunkName: "component---src-pages-equipe-jsx" */),
  "component---src-pages-espace-patient-jsx": () => import("./../../../src/pages/espace-patient.jsx" /* webpackChunkName: "component---src-pages-espace-patient-jsx" */),
  "component---src-pages-espace-professionnel-jsx": () => import("./../../../src/pages/espace-professionnel.jsx" /* webpackChunkName: "component---src-pages-espace-professionnel-jsx" */),
  "component---src-pages-formations-jsx": () => import("./../../../src/pages/formations.jsx" /* webpackChunkName: "component---src-pages-formations-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nos-cliniques-jsx": () => import("./../../../src/pages/nos-cliniques.jsx" /* webpackChunkName: "component---src-pages-nos-cliniques-jsx" */),
  "component---src-pages-politique-de-confidentialite-jsx": () => import("./../../../src/pages/politique-de-confidentialite.jsx" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-jsx" */),
  "component---src-pages-premiere-consultation-jsx": () => import("./../../../src/pages/premiere-consultation.jsx" /* webpackChunkName: "component---src-pages-premiere-consultation-jsx" */),
  "component---src-pages-questionnaire-medical-jsx": () => import("./../../../src/pages/questionnaire-medical.jsx" /* webpackChunkName: "component---src-pages-questionnaire-medical-jsx" */),
  "component---src-pages-referer-un-patient-jsx": () => import("./../../../src/pages/referer-un-patient.jsx" /* webpackChunkName: "component---src-pages-referer-un-patient-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-advice-post-operation-template-jsx": () => import("./../../../src/templates/AdvicePostOperationTemplate.jsx" /* webpackChunkName: "component---src-templates-advice-post-operation-template-jsx" */),
  "component---src-templates-advice-pre-operation-template-jsx": () => import("./../../../src/templates/AdvicePreOperationTemplate.jsx" /* webpackChunkName: "component---src-templates-advice-pre-operation-template-jsx" */),
  "component---src-templates-clinique-template-clinique-template-jsx": () => import("./../../../src/templates/CliniqueTemplate/CliniqueTemplate.jsx" /* webpackChunkName: "component---src-templates-clinique-template-clinique-template-jsx" */),
  "component---src-templates-service-template-service-template-jsx": () => import("./../../../src/templates/ServiceTemplate/ServiceTemplate.jsx" /* webpackChunkName: "component---src-templates-service-template-service-template-jsx" */),
  "component---src-templates-training-template-training-template-jsx": () => import("./../../../src/templates/TrainingTemplate/TrainingTemplate.jsx" /* webpackChunkName: "component---src-templates-training-template-training-template-jsx" */)
}

