import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import StyledButton, { IconWrapper, TextWrapper } from './button.styles';
import BubbleLoader from '../Loaders/BubbleLoader';

const Button = ({
  href,
  to,
  type,
  primary,
  outlined,
  disabled,
  children,
  renderIcon,
  iconFirst,
  hasIconOnly,
  white,
  loading,
  ...props
}) => {
  let SelectedButton = StyledButton;
  let selectedType = type || 'button';

  if (href) {
    SelectedButton = StyledButton.withComponent('a');

    selectedType = undefined;
  }

  if (to) {
    SelectedButton = StyledButton.withComponent(Link);

    selectedType = undefined;
  }

  return (
    <SelectedButton
      type={selectedType}
      href={href}
      to={to}
      primary={primary}
      outlined={outlined}
      disabled={disabled}
      hasIcon={!!renderIcon}
      iconFirst={iconFirst}
      hasIconOnly={hasIconOnly}
      white={white}
      {...props}
    >
      {loading ? (
        <BubbleLoader />
      ) : (
        <>
          {!hasIconOnly && <TextWrapper>{children}</TextWrapper>}

          {renderIcon && <IconWrapper>{renderIcon}</IconWrapper>}
        </>
      )}
    </SelectedButton>
  );
};

Button.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'reset', 'submit']),
  primary: PropTypes.bool,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  hasIconOnly: PropTypes.bool,
  iconFirst: PropTypes.bool,
  children: PropTypes.node.isRequired,
  renderIcon: PropTypes.node,
  white: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  href: undefined,
  to: undefined,
  type: 'button',
  primary: false,
  outlined: false,
  disabled: false,
  renderIcon: undefined,
  hasIconOnly: false,
  iconFirst: false,
  white: false,
  loading: false,
};

export default Button;
