import { lineHeights } from './typography';

const ratio = lineHeights.body[0];
const s0 = 1;
const sm1 = s0 * ratio;
const sm2 = sm1 * ratio;
const sm3 = sm2 * ratio;
const sm4 = sm3 * ratio;
const sm5 = sm4 * ratio;
const sm6 = sm5 * ratio;

const sd1 = s0 / ratio;
const sd2 = sd1 / ratio;
const sd3 = sd2 / ratio;
const sd4 = sd3 / ratio;
const sd5 = sd4 / ratio;
const sd6 = sd5 / ratio;

export default {
  ratio,
  s0,
  sm1,
  sm2,
  sm3,
  sm4,
  sm5,
  sm6,
  sd1,
  sd2,
  sd3,
  sd4,
  sd5,
  sd6,
};
