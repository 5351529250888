/* eslint-disable prefer-destructuring */
// const breakpoints = [640, 832, 1024, 1280, 1920];
const breakpoints = [576, 640, 768, 832, 992, 1024, 1280, 1440, 1560, 1920];

// 640, 1280
breakpoints.fonts = [breakpoints[1], breakpoints[6]];

// 768, 992, 1280
breakpoints.spacings = [breakpoints[2], breakpoints[4], breakpoints[6]];

// spacing
// [12, 16, 24, 28, 40, 60, 80, 120, 168, 960, 1276, 1440, 1560, 1920]
export default breakpoints;
