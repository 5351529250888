// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { ContactFormProvider } from './src/contexts/contactFormContext';
import { NotificationsCenterProvider } from './src/contexts/notificationsCenterContext';
import { SnipcartProvider } from './src/contexts/snipcartContext';

export const WrapRootElement = ({ element }) => {
  return (
    <SnipcartProvider>
      <NotificationsCenterProvider>
        <ContactFormProvider>{element}</ContactFormProvider>
      </NotificationsCenterProvider>
    </SnipcartProvider>
  );
};

WrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};

export default WrapRootElement;
