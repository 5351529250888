// vendors
import React, { useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

export const SnipcartContext = React.createContext();

export const useSnipcart = () => useContext(SnipcartContext);

export const SnipcartProvider = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const init = useCallback(() => {
    setIsLoaded(true);
  }, [setIsLoaded]);

  const waitReady = useCallback(async () => {
    await Promise.all([window.Snipcart.ready]);

    setIsLoaded(true);
  }, [setIsLoaded]);

  useEffect(() => {
    if (window.Snipcart) {
      waitReady();
    } else {
      document.addEventListener('snipcart.ready', init);
      document.addEventListener('snipcart.initialized', init);

      return () => {
        document.removeEventListener('snipcart.ready', init);
        document.removeEventListener('snipcart.initialized', init);
      };
    }

    return () => {};
  }, [init, waitReady]);

  return (
    <SnipcartContext.Provider value={isLoaded ? window.Snipcart : null}>
      {children}
    </SnipcartContext.Provider>
  );
};

SnipcartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
