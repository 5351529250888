// vendors
import styled from 'styled-components';
import zIndices from '../../styles/zIndices';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  z-index: ${zIndices.popover};

  width: 100vw;
`;

export const UpperBannerWrapper = styled.div``;
