// vendors
import React from 'react';
import { Container, Dot } from './BubbleLoader.styles';

const BubbleLoader = () => {
  return (
    <Container>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  );
};

export default BubbleLoader;
