// vendors
import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

class Timer {
  constructor(callback = () => {}, delay = 5000) {
    this.timerId = setTimeout(callback, delay);
    this.start = delay;
    this.remaining = delay;
    this.callback = callback;

    this.resume();
  }

  clear() {
    clearTimeout(this.timerId);
  }

  pause() {
    clearTimeout(this.timerId);

    this.remaining -= Date.now() - this.start;
  }

  resume() {
    this.start = Date.now();

    clearTimeout(this.timerId);

    this.timerId = setTimeout(this.callback, this.remaining);
  }
}

const NotificationController = ({
  component: Notification,
  autoDismiss,
  autoDismissTimeout,
  children,
  onDismiss,
  ...rest
}) => {
  const [isRunning, setIsRunning] = useState(autoDismiss);
  const timerRef = useRef(null);

  const startTimer = useCallback(() => {
    if (!autoDismiss) return;

    setIsRunning(true);

    timerRef.current = new Timer(onDismiss, autoDismissTimeout);
  }, [autoDismiss, autoDismissTimeout, onDismiss]);

  const clearTimer = useCallback(() => {
    if (timerRef.current) timerRef.current.clear();
  }, []);

  useEffect(() => {
    if (autoDismiss && !timerRef.current) startTimer();

    return () => clearTimer();
  }, [startTimer, clearTimer, autoDismiss, onDismiss, autoDismissTimeout]);

  useEffect(() => {
    if (!timerRef.current) return;

    if (isRunning) {
      timerRef.current.resume();
    } else {
      timerRef.current.pause();
    }
  }, [autoDismiss, isRunning]);

  const handleMouseEnter = () => {
    if (!timerRef.current) return;

    timerRef.current.pause();

    setIsRunning(false);
  };

  const handleMouseLeave = () => {
    if (!timerRef.current) return;

    timerRef.current.resume();

    setIsRunning(true);
  };

  return (
    <Notification
      isRunning={isRunning}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDismiss={onDismiss}
      {...rest}
    >
      {children}
    </Notification>
  );
};

NotificationController.propTypes = {
  component: PropTypes.node.isRequired,
  autoDismiss: PropTypes.bool,
  autoDismissTimeout: PropTypes.number,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
};

NotificationController.defaultProps = {
  autoDismiss: false,
  autoDismissTimeout: 6000,
  onDismiss: () => {},
};

export default NotificationController;
