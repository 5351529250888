// vendors
import React from 'react';
import PropTypes from 'prop-types';
import { UpperBannerWrapper, Container } from './NotificationsCenter.styles';
import Banner from './Banner';
import NotificationController from './NotificationController';

const NotificationsCenter = ({ notifications, removeNotification }) => {
  const handleDismiss = (id, callback = () => {}) => {
    callback();

    removeNotification(id);
  };

  return (
    <Container>
      <UpperBannerWrapper>
        {notifications.map(
          ({
            id,
            component = Banner,
            content: children,
            onDismiss,
            ...rest
          }) => (
            <NotificationController
              key={id}
              id={id}
              component={component}
              onDismiss={() => handleDismiss(id, onDismiss)}
              {...rest}
            >
              {children}
            </NotificationController>
          )
        )}
      </UpperBannerWrapper>
    </Container>
  );
};

NotificationsCenter.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape()),
  removeNotification: PropTypes.func,
};

NotificationsCenter.defaultProps = {
  notifications: [],
  removeNotification: () => {},
};

export default NotificationsCenter;
