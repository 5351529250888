// vendors
import React from 'react';
import PropTypes from 'prop-types';
import {
  Wrapper,
  Container,
  DismissButtonWrapper,
  IconWrapper,
  MessageWrapper,
  DismissButton,
} from './Banner.styles';
import IconError from '../../../images/IconError';
import IconSuccess from '../../../images/IconSuccess';

export const bannerType = {
  info: 'info',
  error: 'error',
  blocked: 'blocked',
  severeWarning: 'severeWarning',
  success: 'success',
  warning: 'warning',
};

const icons = {
  error: (props) => <IconError {...props} />,
  success: (props) => <IconSuccess {...props} />,
};

const Banner = ({
  type,
  onDismiss,
  dismissButtonLabel,
  iconRenderer,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  const Icon = type ? icons[type] : undefined;

  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Wrapper>
        {iconRenderer && <IconWrapper>{iconRenderer}</IconWrapper>}

        {!iconRenderer && Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}

        <MessageWrapper>{children}</MessageWrapper>

        <DismissButtonWrapper>
          <DismissButton outlined onClick={onDismiss}>
            {dismissButtonLabel}
          </DismissButton>
        </DismissButtonWrapper>
      </Wrapper>
    </Container>
  );
};

Banner.propTypes = {
  type: PropTypes.string,
  iconRenderer: PropTypes.node,
  children: PropTypes.node.isRequired,
  dismissButtonLabel: PropTypes.string.isRequired,
  onDismiss: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

Banner.defaultProps = {
  type: undefined,
  iconRenderer: undefined,
  onDismiss: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default Banner;
