// vendors
import React from 'react';
import theme from '../styles/theme';
import colors from '../styles/colors';

const IconSuccess = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 110.89 110.89'
    {...props}
  >
    <circle fill={theme.colors.success} cx='55.48' cy='55.39' r='55.38' />
    <polygon
      fill={colors.veryLightPink300}
      points='48.62 76.87 30.21 53.83 38.08 47.57 49.02 61.3 73.26 33.9 80.75 40.49 48.62 76.87'
    />
  </svg>
);

export default IconSuccess;
