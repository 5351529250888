import colors from './colors';

export const fontSizes = {
  body: [16, 17, 19],
  h1: [30, 44, 56],
  h2: [30, 32, 38],
  intro: [20, 24, 30],
};

export const fontFamilies = {
  body: 'IBM Plex Sans',
};

export const fontWeights = {
  body: 400,
  medium: 500,
  semibold: 600,
  heading: 400,
};

export const lineHeights = {
  body: [1.33, 1.53], // [20, 29]
  heading: [1.17, 1.18], // [35, 66]
  intro: [1.31, 1.33],
};

export const letterSpacings = {
  body: ['normal', 'normal'],
  heading: ['normal', 'normal'],
};

export const fontColors = {
  body: colors.mineShaft,
  heading: colors.azure,
};

export default {
  fontSizes,
  fontFamilies,
  fontWeights,
  lineHeights,
  letterSpacings,
  fontColors,
};
