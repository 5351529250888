// vendors
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

export const ContactFormContext = React.createContext();

export const useContactForm = () => useContext(ContactFormContext);

export const ContactFormProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [preSelectedSpecialist, setPreSelectedSpecialist] = useState();
  const [preSelectedClinique, setPreSelectedClinique] = useState('');

  const open = (
    props = {
      specialist: undefined,
      clinique: '',
    }
  ) => {
    const params = {
      specialist: undefined,
      clinique: '',
      ...props,
    };

    if (params.specialist) {
      setPreSelectedSpecialist(params.specialist);
    }

    setPreSelectedClinique(params.clinique);

    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <ContactFormContext.Provider
      value={{
        open,
        close,
        isOpen,
        preSelectedSpecialist,
        preSelectedClinique,
      }}
    >
      {children}
    </ContactFormContext.Provider>
  );
};

ContactFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
