import { css } from 'styled-components';

import breakpointsRange from '../utils/breakpointsRange';

const stack = (sizes, breakpoints) => {
  return css`
    > * + * {
      ${breakpointsRange([{ prop: 'margin-top', sizes }], breakpoints)};
    }
  `;
};

const grid = (minWidth, gap, length = 'auto-fit') => {
  return css`
    display: grid;
    grid-gap: ${gap};
    grid-template-columns: repeat(
      ${length},
      minmax(min(${minWidth}, 100%), 1fr)
    );
  `;
};

export default {
  grid,
  stack,
};
